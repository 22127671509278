<template>
  <moe-page title="宠物类别管理" id="pet-cate-list">
    <!-- 筛选信息 -->
    <moe-inquire @search="categorySearch">
      <el-form-item label="宠物类型">
        <moe-select v-model="categoryParams.pesType" type="petType" placeholder="选择宠物类型" />
      </el-form-item>
      <el-form-item label="宠物体型">
        <moe-select v-model="categoryParams.shape" type="petContour" placeholder="选择宠物体型" />
      </el-form-item>

      <el-form-item class="weight-line">
        <el-form-item class="weightMin" label="体型最小范围">
          <el-input @blur="if_weight" v-model.number="categoryParams.weightMin" placeholder="" maxlength="50" clearable />
        </el-form-item>
        <span class="weight-span"> ~ </span>
        <el-form-item class="weightMax" label="体型最大范围">
          <el-input @blur="if_weight" v-model.number="categoryParams.weightMax" placeholder="" maxlength="50" clearable />
        </el-form-item>
      </el-form-item>

      <!-- <el-form-item label="宠物体重范围">
        <el-select
          v-model="weightRange"
          placeholder="选择宠物体重范围"
          clearable
          @change="
            (e) => ([categoryParams.weightMin, categoryParams.weightMax] = e)
          "
        >
          <el-option label="0~10" :value="[0, 10]" />
          <el-option label="10~20" :value="[10, 20]" />
          <el-option label="20~30" :value="[20, 30]" />
          <el-option label="30以上" :value="[30, '']" />
        </el-select>
      </el-form-item> -->
    </moe-inquire>

    <!-- 宠物类别信息 -->
    <moe-table
      ref="categoryTable"
      url="/petsType/pageList"
      :params="categoryParams"
      row-key="id"
      :number-show="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="danger" icon="el-icon-delete" @click="batchDelete">批量删除</el-button>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/pet-category/add')">类别添加</el-button>
      </template>
      <el-table-column type="selection" reserve-selection label="ID" width="60" fixed="left" />
      <el-table-column prop="varieties" label="宠物类别" min-width="100" />
      <el-table-column prop="category" label="宠物类型" min-width="100">
        <template slot-scope="{ row }">
          {{ $moe_format.getPetType(row.pesType) }}
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="宠物体型" min-width="100">
        <template slot-scope="{ row }">
          {{ $moe_format.getPetContour(row.shape) }}
        </template>
      </el-table-column>
      <el-table-column prop="state" label="体重范围 /kg" min-width="150">
        <template slot-scope="{ row }">
          {{ row.weightMin + '~' + row.weightMax }}
        </template>
      </el-table-column>
      <el-table-column prop="cereal" label="图片" min-width="100">
        <template slot-scope="{ row }">
          <moe-image :src="row.img" width="50px" height="50px"></moe-image>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/pet-category/amend?id=${row.id}`)">修改</el-button>
          <el-button type="success" size="mini" icon="el-icon-view" @click="$router.push(`/pet-category/detail?id=${row.id}`)">详情</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'categoryList',
  data() {
    //宠物类别筛选信息
    let categoryParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      pesType: '', //类别
      shape: '', //体型
      weightMax: '', //最大区间
      weightMin: '', //最小区间
    };

    return {
      categoryParams, //宠物类别筛选信息
      weightRange: [], //体重范围
    };
  },
  watch:{

  },

  methods: {
    /**
     * 处理体重范围
    */
    //判断最小值
    if_weight(){
      var {weightMax,weightMin}=this.categoryParams;
      console.log(weightMin,weightMax)
      if(weightMin!==''&&weightMax!==''){
        // 大于
        if(weightMin>weightMax){
          this.categoryParams.weightMin=weightMax;
          this.categoryParams.weightMax=weightMin;
        }
      }
    },

    /**
     * 批量删除
     **/
    batchDelete() {
      //获取已选数据ID
      let ids = this.$refs.categoryTable.getSelectId();
      if (ids.length === 0) {
        this.$moe_msg.warning('请选择数据 !');
        return;
      }

      this.$moe_layer.confirm('是否要删除选中的宠物类别信息 ?', () => {
        // 调用删除宠物类别API
        this.$moe_api.PET_API.petCategoryDelete({ ids: ids.join(',') }).then(
          (data) => {
            if (data.code == 200) {
              this.$moe_msg.success('删除成功');

              //刷新宠物类别表格
              this.$refs.categoryTable.clearLoadData();
            } else {
              this.$moe_msg.error(data.message);
            }
          }
        );
      });
    },

    /**
     * 宠物类别搜索
     **/
    categorySearch(isSearch) {
      if (!isSearch) {
        this.categoryParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          pesType: '', //类别
          shape: '', //体型
          weightMax: '', //最大区间
          weightMin: '', //最小区间
        };

        this.weightRange = [];
      }

      //刷新宠物类别表格
      this.$refs['categoryTable'].clearLoadData();
    },
  },
};
</script>

<style lang="scss">

  #pet-cate-list{

    // 宠物体重
    .weight-line{
      position:relative;

      .weight-span{
        position: absolute;
        top:35px;
        left:144px;
      }

      .weightMin,.weightMax{
        width:120px;
      }
    }
  }


</style>